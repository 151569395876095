const translations = {
  ru: {
    lng_select_welcome_bonus: 'ВЫБЕРИ ПРИВЕТСТВЕННЫЙ БОНУС',
    lng_registration: 'РЕГИСТРАЦИЯ',
    lng_login: 'ВХОД',
    lng_by_email: 'По e-mail',
    lng_by_phone: 'По телефону',
    lng_one_click: 'В 1 клик',
    lng_email: 'E-mail',
    lng_password: 'Пароль',
    lng_phone: 'Телефон',
    lng_registration_bonus: 'Ваш бонус при регистрации — ',
    lng_casino_bonus_details: 'Бонус Казино 50000₽ + 300FS новым игрокам',
    lng_sport_bonus_details: 'Спортбонус 4 фрибета 10000₽ новым игрокам',
    lng_accept_terms_privacy: 'Мне больше 18 лет и я согласен с правилами проведения игр и политикой конфиденциальности',
    lng_accept_euromania: 'Я согласен принять участие в акции "Евромания" и получить стартовые 30 баллов без депозита',
    lng_accept_offers: 'Я согласен получать выгодные предложения по e-mail и sms',
    lng_ac_t_privacy_1: 'Мне больше 18 лет и я согласен с&nbsp;',
    lng_ac_t_privacy_2: 'правилами проведения игр&nbsp;',
    lng_ac_t_privacy_3: 'и&nbsp;',
    lng_ac_t_privacy_4: 'политикой конфиденциальности&nbsp;',
    lng_ac_t_privacy_5: '',
    lng_register: 'РЕГИСТРАЦИЯ',
    lng_support_24_7: 'Поддержка 24/7',
    lng_sport_bonus_btn: 'Спортбонус',
    lng_casino_bonus_btn: 'Бонус Казино',
    lng_sport_new_gamers: 'новым игрокам',
    lng_casino_new_gamers: 'новым игрокам',
    lng_sport_bonus_new_players: '4 ФРИБЕТА 10000₽',
    lng_casino_bonus_new_players: '50000₽ + 300FS',
    lng_dialog_sport_bonus: `
      <p class="body-large"><strong>1. Регистрируйся</strong><br>и выбирай спортбонус</p>
      <p class="body-large"><strong>2. Пополни счет</strong><br>на сумму от 500₽</p>
      <p class="body-large"><strong>3. Забирай бонус!</strong><br>сразу 4 фрибета до 10 000₽</p>
      <p class="body-large"><strong>Количество фрибетов зависит от суммы первого депозита. Чем выше депозит - тем выгоднее фрибеты:</strong></p>
      <p class="body-large">- 4 фрибета 5 000₽ + 2 000₽ + 2 000₽ + 1 000₽ за депозит от 7 000₽</p>
      <p class="body-large">- 3 фрибета 1 500₽ + 1 500₽ + 1 000₽ за депозит от 3 000₽</p>
      <p class="body-large">- 2 фрибета 700₽ + 500₽ за депозит от 1 000₽</p>
      <p class="body-large">- 2 фрибета 500₽ + 500₽ за депозит от 500₽</p>
    `,
    lng_dialog_casino_bonus: `
      <p class="body-large"><strong>1. Регистрируйся</strong><br>и выбирай бонус Казино</p>
      <p class="body-large"><strong>2. Пополни счет</strong><br>на сумму от 8 000₽</p>
      <p class="body-large"><strong>3. Забирай бонус!</strong><br>до 50 000₽ и 300 фриспинов</p>
      <p class="body-large"><strong>Размер бонуса зависит от суммы первого депозита. Чем выше депозит - тем больше приветственный БУСТ:</strong></p>
      <p class="body-large">- СУПЕРБУСТ 300FS + 300% от пополнения за депозит от 8 000₽</p>
      <p class="body-large">- БУСТ 100FS + 150% от пополнения за депозит от 500₽</p>
    `,
  },
  kz_ru: {
    lng_select_welcome_bonus: 'ВЫБЕРИ ПРИВЕТСТВЕННЫЙ БОНУС',
    lng_registration: 'РЕГИСТРАЦИЯ',
    lng_login: 'ВХОД',
    lng_by_email: 'По e-mail',
    lng_by_phone: 'По телефону',
    lng_one_click: 'В 1 клик',
    lng_email: 'E-mail',
    lng_password: 'Пароль',
    lng_phone: 'Телефон',
    lng_registration_bonus: 'Ваш бонус при регистрации — ',
    lng_casino_bonus_details: 'Бонус Казино 250000₸ + 300FS новым игрокам',
    lng_sport_bonus_details: 'Спортбонус 4 фрибета 50000₸ новым игрокам',
    lng_accept_terms_privacy: 'Мне больше 18 лет и я согласен с правилами проведения игр и политикой конфиденциальности',
    lng_accept_euromania: 'Я согласен принять участие в акции "Евромания" и получить стартовые 30 баллов без депозита',
    lng_accept_offers: 'Я согласен получать выгодные предложения по e-mail и sms',
    lng_ac_t_privacy_1: 'Мне больше 18 лет и я согласен с&nbsp;',
    lng_ac_t_privacy_2: 'правилами проведения игр&nbsp;',
    lng_ac_t_privacy_3: 'и&nbsp;',
    lng_ac_t_privacy_4: 'политикой конфиденциальности&nbsp;',
    lng_ac_t_privacy_5: '',
    lng_register: 'РЕГИСТРАЦИЯ',
    lng_support_24_7: 'Поддержка 24/7',
    lng_sport_bonus_btn: 'Спортбонус',
    lng_casino_bonus_btn: 'Бонус Казино',
    lng_sport_new_gamers: 'новым игрокам',
    lng_casino_new_gamers: 'новым игрокам',
    lng_sport_bonus_new_players: '4 ФРИБЕТА 50000₸',
    lng_casino_bonus_new_players: '250000₸ + 300FS',
    lng_dialog_sport_bonus: `
      <p class="body-large"><strong>1. Регистрируйся</strong><br>и выбирай спортбонус</p>
      <p class="body-large"><strong>2. Пополни счет</strong><br>на сумму от 2 500₸</p>
      <p class="body-large"><strong>3. Забирай бонус!</strong><br>сразу 4 фрибета до 50 000₸</p>
      <p class="body-large"><strong>Количество фрибетов зависит от суммы первого депозита. Чем выше депозит - тем выгоднее фрибеты:</strong></p>
      <p class="body-large">- 4 фрибета 25 000₸ + 10 000₸ + 10 000₸ + 5 000₸ за депозит от 35 000₸</p>
      <p class="body-large">- 3 фрибета 7 500₸ + 7 500₸ + 5 000₸ за депозит от 15 000₸</p>
      <p class="body-large">- 2 фрибета 3 500₸ + 2 500₸ за депозит от 5 000₸</p>
      <p class="body-large">- 2 фрибета 1 250₸ + 1 250₸ за депозит от 2 500₸</p>
    `,
    lng_dialog_casino_bonus: `
      <p class="body-large"><strong>1. Регистрируйся</strong><br>и выбирай бонус Казино</p>
      <p class="body-large"><strong>2. Пополни счет</strong><br>до 250000₸ и 300 фриспинов</p>
      <p class="body-large"><strong>3. Забирай бонус!</strong><br>до 50000₽ и 300 фриспинов</p>
      <p class="body-large"><strong>Размер бонуса зависит от суммы первого депозита. Чем выше депозит - тем больше приветственный БУСТ:</strong></p>
      <p class="body-large">- СУПЕРБУСТ 300FS + 300% от пополнения за депозит от 40000₸</p>
      <p class="body-large">- БУСТ 100FS + 150% от пополнения за депозит от 500₸</p>
    `,
  },
  kz: {
    lng_select_welcome_bonus: 'ІЛТИПАТ БОНУСЫН ТАҢДА',
    lng_registration: 'ТІРКЕЛУ',
    lng_login: 'КІРУ',
    lng_by_email: 'e-mail бойынша',
    lng_by_phone: 'Телефон арқылы',
    lng_one_click: '1 рет басу арқылы',
    lng_email: 'E-mail',
    lng_password: 'Құпия сөз',
    lng_phone: 'Телефон',
    lng_registration_bonus: 'Тіркелу кезіндегі бонусыңыз — ',
    lng_casino_bonus_details: 'Казино бонусы Жаңа ойыншыларға 250000₸ + 300FS',
    lng_sport_bonus_details: 'Спортбонус Жаңа ойыншыларға 4 фрибет 50000₸',
    lng_accept_terms_privacy: 'Менің жасым 18-ден асқан және мен ойын ережелері мен құпиялылық саясатымен келісемін',
    lng_accept_euromania: 'Мен «€уроқұмарлық» науқанына қатысуға және депозитсіз бастапқы 30 ұпайды алуға келісемін',
    lng_accept_offers: 'Мен e-mail және sms арқылы тиімді ұсыныстарды алуға келісемін',
    lng_ac_t_privacy_1: 'Менің жасым 18-ден асқан және мен&nbsp;',
    lng_ac_t_privacy_2: 'ойын ережелері&nbsp;',
    lng_ac_t_privacy_3: 'мен&nbsp;',
    lng_ac_t_privacy_4: 'құпиялылық саясатымен&nbsp;',
    lng_ac_t_privacy_5: 'келісемін',
    lng_register: 'ТІРКЕЛУ',
    lng_support_24_7: '24/7 қолдау',
    lng_sport_bonus_btn: 'Спортбонус',
    lng_casino_bonus_btn: 'Казино бонусы',
    lng_sport_new_gamers: 'Жаңа ойыншыларға',
    lng_casino_new_gamers: 'Жаңа ойыншыларға',
    lng_sport_bonus_new_players: '4 ФРИБЕТ 50000₸',
    lng_casino_bonus_new_players: '250000₸ + 300FS',
    lng_dialog_sport_bonus: `
      <p class="body-large"><strong>1. Тіркел</strong><br>де спортбонусты таңда</p>
      <p class="body-large"><strong>2. Шотты толтыр</strong><br>2 500₸ және одан жоғары сомаға</p>
      <p class="body-large"><strong>3. Бонусты ал!</strong><br>бірден 4 фрибет 50 000₸ дейін</p>
      <p class="body-large"><strong>Фрибеттер саны бірінші депозиттің сомасына байланысты. Депозит неғұрлым жоғары болса, фрибеттер соғұрлым тиімді болады:</strong></p>
      <p class="body-large">- сомасы 35 000₸ асатын депозит үшін 4 фрибет 25 000₸ + 10 000₸ + 10 000₸ + 5 000₸</p>
      <p class="body-large">- сомасы 15 000₸ асатын депозит үшін 3 фрибет 7 500₸ + 7 500₸ + 5 000₸</p>
      <p class="body-large">- сомасы 5 000₸ асатын депозит үшін 2 фрибет 3 500₸ + 2 500₸</p>
      <p class="body-large">- сомасы 2 500₸ асатын депозит үшін 2 фрибет 1 250₸ + 1 250₸</p>
    `,
    lng_dialog_casino_bonus: `
      <p class="body-large"><strong>1. Тіркел</strong><br>және Казино бонусын таңда</p>
      <p class="body-large"><strong>2. Шотты толтыр</strong><br>40 000₸ және одан асатын сомаға</p>
      <p class="body-large"><strong>3. Бонусты ал!</strong><br>250 000₸ және 300 фриспинге дейін</p>
      <p class="body-large"><strong>Бонус мөлшері бірінші депозит сомасына байланысты. Депозит неғұрлым жоғары болса, соғұрлым ілтипат БУСТ артады:</strong></p>
      <p class="body-large">- СУПЕРБУСТ 300FS + 300% (40 000₸ бастап депозит үшін толықтыру сомасынан)</p>
      <p class="body-large">- БУСТ 100FS + 150% (500₸ бастап депозит үшін толықтыру сомасынан)</p>
    `,
  },
  az: {
    lng_select_welcome_bonus: 'WELCOME BONUSUNU SEÇ',
    lng_registration: 'QEYDİYYAT',
    lng_login: 'GİRİŞ',
    lng_by_email: 'E-poçt vasitəsi ilə',
    lng_by_phone: 'Telefon vasitəsi ilə',
    lng_one_click: '1 kliklə',
    lng_email: 'E-mail',
    lng_password: 'Parol',
    lng_phone: 'Telefon',
    lng_registration_bonus: 'Qeydiyyat bonusunuz — ',
    lng_casino_bonus_details: 'Casino Bonusu Yeni oyunçular üçün ₼1000 + 300FS',
    lng_sport_bonus_details: 'Sportbonus Yeni oyunçular üçün 4 fribet ₼200',
    lng_accept_terms_privacy: 'Mənim 18 yaşım var və mən oyunların keçiriliməsi qaydalarıvə məxfilik siyasəti ilə razıyam',
    lng_accept_euromania: 'Mən “Avromaniya” aksiyada iştirak etməyə və depozitsiz Welcome 30 bal almağa razıyam!',
    lng_accept_offers: 'Sms və e-poçt vasitəsilə xəbərlərin göndərilməsinə razılığımı bildirirəm',
    lng_ac_t_privacy_1: 'Mənim 18 yaşım var və mən&nbsp;',
    lng_ac_t_privacy_2: 'oyunların keçiriliməsi qaydaları&nbsp;',
    lng_ac_t_privacy_3: 'və&nbsp;',
    lng_ac_t_privacy_4: 'məxfilik siyasəti&nbsp;',
    lng_ac_t_privacy_5: 'ilə razıyam',
    lng_register: 'QEYDİYYAT',
    lng_support_24_7: '24/7 dəstək xidməti',
    lng_sport_bonus_btn: 'Idman bonusları',
    lng_casino_bonus_btn: 'Casino bonusları',
    lng_sport_new_gamers: 'Yeni oyunçular üçün',
    lng_casino_new_gamers: 'Yeni oyunçular üçün',
    lng_sport_bonus_new_players: '4 FRIBET ₼200',
    lng_casino_bonus_new_players: '₼1000 + 300FS',
    lng_dialog_sport_bonus: `
      <p class="body-large"><strong>1. Qeydiyyatdan keç</strong><br>və idman bonusu seç</p>
      <p class="body-large"><strong>2. Hesabını doldur</strong><br>₼10-dən başlayan məbləğində</p>
      <p class="body-large"><strong>3. Bonus qazan!</strong><br>Bir dəfəyə ₼200-ə qədər 4 fribet!</p>
      <p class="body-large"><strong>Fribet sayı ilk depozitin məbləğindən asılıdır. Depozit nə qədər yüksək olsa, fribet bir o qədər sərfəli olur:</strong></p>
      <p class="body-large">- ₼140 depozit üçün 4 fribet ₼100 + ₼40 + ₼40 + ₼20</p>
      <p class="body-large">- ₼60 depozit üçün 3 fribet ₼40 + ₼20 + ₼20</p>
      <p class="body-large">- ₼20 depozit üçün 2 fribet ₼15 + ₼10</p>
      <p class="body-large">- ₼10 depozit üçün 2 fribet ₼5 + ₼5</p>
    `,
    lng_dialog_casino_bonus: `
      <p class="body-large"><strong>1. Qeydiyyatdan keç</strong><br>və Casino bonusu seç</p>
      <p class="body-large"><strong>2. Hesabını doldur</strong><br>₼200-dən başlayan məbləğində</p>
      <p class="body-large"><strong>3. Bonus qazan!</strong><br>₼1000-ə qədər və 300 frispin</p>
      <p class="body-large"><strong>bonusun məbləği ilk depozitin məbləğindən asılıdır. Depozit nə qədər yüksək olarsa, Welcome BOOST bir o qədər yüksək olar:</strong></p>
      <p class="body-large">- SUPERBOOST 300FS + 300% doldurma məbləğindən ₼200-dan depozit üçün</p>
      <p class="body-large">- BOOST 100FS + 150% doldurma məbləğindən ₼10-dan depozit üçün</p>
    `,
  },
};

export default translations;

// class BonusManager {
//   constructor() {
//     this.bonusSportRadio = document.getElementById('bonus-sport');
//     this.bonusCasinoRadio = document.getElementById('bonus-casino');
//
//     this.currentBonusSport = document.querySelector('.current-bonus__sport');
//     this.currentBonusCasino = document.querySelector('.current-bonus__casino');
//
//     this.updateActiveClass = this.updateActiveClass.bind(this);
//   }
//
//   init() {
//     this.bonusSportRadio.addEventListener('change', this.updateActiveClass);
//     this.bonusCasinoRadio.addEventListener('change', this.updateActiveClass);
//
//     this.updateActiveClass();
//   }
//
//   updateActiveClass() {
//     if (this.bonusSportRadio.checked) {
//       this.currentBonusSport.classList.remove('is-active');
//       this.currentBonusCasino.classList.add('is-active');
//     } else if (this.bonusCasinoRadio.checked) {
//       this.currentBonusSport.classList.add('is-active');
//       this.currentBonusCasino.classList.remove('is-active');
//     }
//   }
// }
//
// export default BonusManager;

class BonusManager {
  constructor() {
    this.bonusSportRadio = document.getElementById('bonus-sport');
    this.bonusCasinoRadio = document.getElementById('bonus-casino');

    this.currentBonus = document.querySelector('.current-bonus');

    this.updateActiveClass = this.updateActiveClass.bind(this);
  }

  init() {
    this.bonusSportRadio.addEventListener('change', this.updateActiveClass);
    this.bonusCasinoRadio.addEventListener('change', this.updateActiveClass);

    this.updateActiveClass();
  }

  updateActiveClass() {
    if (this.bonusSportRadio.checked) {
      this.currentBonus.classList.remove('current-bonus--casino');
      this.currentBonus.classList.add('current-bonus--sport');
    } else if (this.bonusCasinoRadio.checked) {
      this.currentBonus.classList.remove('current-bonus--sport');
      this.currentBonus.classList.add('current-bonus--casino');
    }
  }
}

export default BonusManager;

class AuthTabs {
  #emailTab;

  #phoneTab;

  #oneClickTab;

  #emailField;

  #phoneField;

  #passwordField;

  constructor() {
    this.#emailTab = document.querySelector('.auth-tab-email');
    this.#phoneTab = document.querySelector('.auth-tab-phone');
    this.#oneClickTab = document.querySelector('.auth-tab-oneclick');

    this.#emailField = document.querySelector('.auth-field-email');
    this.#phoneField = document.querySelector('.auth-field-phone');
    this.#passwordField = document.querySelector('.auth-field-password');
  }

  init() {
    this.#emailTab.addEventListener('click', () => this.#activateEmailTab());
    this.#phoneTab.addEventListener('click', () => this.#activatePhoneTab());
    this.#oneClickTab.addEventListener('click', () => this.#activateOneClickTab());

    this.#emailTab.click();
  }

  #removeActiveClass() {
    this.#emailTab.classList.remove('is-active');
    this.#phoneTab.classList.remove('is-active');
    this.#oneClickTab.classList.remove('is-active');
  }

  // eslint-disable-next-line class-methods-use-this
  #addDataIgnore(...fields) {
    fields.forEach((field) => field.setAttribute('data-ignore', 'true'));
  }

  // eslint-disable-next-line class-methods-use-this
  #removeDataIgnore(...fields) {
    fields.forEach((field) => field.removeAttribute('data-ignore'));
  }

  #activateEmailTab() {
    this.#removeActiveClass();
    this.#emailTab.classList.add('is-active');
    this.#removeDataIgnore(this.#emailField, this.#passwordField);
    this.#addDataIgnore(this.#phoneField);
  }

  #activatePhoneTab() {
    this.#removeActiveClass();
    this.#phoneTab.classList.add('is-active');
    this.#removeDataIgnore(this.#phoneField, this.#passwordField);
    this.#addDataIgnore(this.#emailField);
  }

  #activateOneClickTab() {
    this.#removeActiveClass();
    this.#oneClickTab.classList.add('is-active');
    this.#addDataIgnore(this.#emailField, this.#phoneField, this.#passwordField);
  }
}

export default AuthTabs;

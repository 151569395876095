class CurrencyUpdater {
  constructor(selectOptions) {
    this.selectOptions = selectOptions;
  }

  updateCurrency() {
    const currencySelect = document.querySelector('#currency-select');
    const body = document.querySelector('body');

    let selectedCurrency = 'RUB';

    if (body.classList.contains('geo-kz') || body.classList.contains('geo-kz_ru')) {
      selectedCurrency = 'KZT';
    } else if (body.classList.contains('geo-az')) {
      selectedCurrency = 'AZN';
    } else if (body.classList.contains('geo-ru')) {
      selectedCurrency = 'RUB';
    }

    currencySelect.querySelectorAll('option[selected]').forEach((option) => {
      option.removeAttribute('selected');
    });

    const currencyOption = currencySelect.querySelector(`option[value="${selectedCurrency}"]`);

    if (currencyOption) {
      currencyOption.setAttribute('selected', 'selected');
      currencySelect.value = selectedCurrency;
    }

    if (this.selectOptions) {
      this.selectOptions.updateSelects();
    }
  }

  init() {
    this.updateCurrency();

    const body = document.querySelector('body');

    const observer = new MutationObserver(() => {
      this.updateCurrency();
    });

    observer.observe(body, {
      attributes: true,
      attributeFilter: ['class'],
    });
  }
}

export default CurrencyUpdater;

import TextFields from 'text-fields';
import SelectOptions from 'select-options';
import { MaskInput } from 'maska';
import DialogLite from 'dialog-lite';
import AuthTabs from './utils/AuthTabs';
import BonusManager from './utils/BonusManager';
import LanguageManager from './utils/LanguageManager';
import CurrencyUpdater from './utils/CurrencyUpdater';

const App = {
  languageManager: null,
  dialogLite: null,
  selectOptions: new SelectOptions(),
  textFields: new TextFields(),
  authTabs: new AuthTabs(),
  bonusManager: new BonusManager(),
  currencyUpdater: null,

  initLanguageManager() {
    this.languageManager = new LanguageManager({
      languageSelectId: 'country-select',
    });

    this.languageManager.init();
  },

  initCurrencyUpdater() {
    this.currencyUpdater = new CurrencyUpdater(this.selectOptions);

    this.currencyUpdater.init();
  },

  initDialogLite() {
    this.dialogLite = new DialogLite({
      closingButton: true,
      closingBackdrop: true,
    });

    this.dialogLite.init();

    document.querySelectorAll('.open-sport-dialog').forEach((element) => {
      element.addEventListener('pointerdown', () => {
        this.dialogLite.open({ stylingClass: 'dialog-lite--bonus-sport' });
      });
    });

    document.querySelectorAll('.open-casino-dialog').forEach((element) => {
      element.addEventListener('pointerdown', () => {
        this.dialogLite.open({ stylingClass: 'dialog-lite--bonus-casino' });
      });
    });
  },

  async init() {
    await this.textFields.init();
    this.initLanguageManager();
    this.initCurrencyUpdater();
    await this.selectOptions.init();
    await new MaskInput('[data-maska]');
    await this.authTabs.init();
    this.bonusManager.init();
    this.initDialogLite();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());

import translations from '../data/translations';

class LanguageManager {
  #elements;

  #languageSelect;

  constructor({ languageSelectId }) {
    this.#languageSelect = document.getElementById(languageSelectId);
    this.#elements = this.#initializeElements();
  }

  #initializeElements() {
    const elements = {};
    const languages = Object.keys(translations);

    if (languages.length > 0) {
      const sampleTranslation = translations[languages[0]];

      Object.keys(sampleTranslation).forEach((key) => {
        elements[key] = document.getElementById(key);
      });
    }

    return elements;
  }

  initialize() {
    if (this.#languageSelect) {
      this.#languageSelect.addEventListener('change', (event) => {
        const selectedLanguage = event.target.value;

        this.#loadLanguage(selectedLanguage);
        this.#setBodyClass(selectedLanguage);

        const url = new URL(window.location);

        url.searchParams.set('locale', selectedLanguage);

        window.history.pushState({}, '', url);
      });

      this.#setDefaultLanguage();
    } else {
      console.error('Language select element not found');
    }
  }

  #setDefaultLanguage() {
    const urlParams = new URLSearchParams(window.location.search);
    const locale = urlParams.get('locale');
    let defaultLanguage = 'ru';

    if (locale === 'kz') {
      defaultLanguage = 'kz';
    } else if (locale === 'az') {
      defaultLanguage = 'az';
    }

    if (this.#languageSelect) {
      this.#languageSelect.value = defaultLanguage;
      this.#languageSelect.querySelector(`option[value="${defaultLanguage}"]`).setAttribute('selected', 'selected');
    }

    this.#loadLanguage(defaultLanguage);
    this.#setBodyClass(defaultLanguage);
  }

  #loadLanguage(lang) {
    const data = translations[lang];

    if (data) {
      Object.keys(data).forEach((key) => {
        if (this.#elements[key]) {
          const element = this.#elements[key];

          // Используем innerHTML для вставки HTML-кода
          element.innerHTML = data[key];
        }
      });
    }
  }

  #setBodyClass(language) {
    document.body.className = `geo-${language}`;
  }

  init() {
    this.initialize();
    const urlParams = new URLSearchParams(window.location.search);
    const savedLanguage = urlParams.get('locale') || 'ru';

    if (this.#languageSelect) {
      this.#languageSelect.value = savedLanguage;
    }

    this.#setBodyClass(savedLanguage);
  }
}

export default LanguageManager;
